<template>
  <div class="w-full">
    <portal to="navbar-title">
      <h2 class="text-2xl xl:text-xl sm:text-base">Mes révélations</h2>
    </portal>
    <RevelationMobileButton />
    <div
      class="w-full pt-11 md:pt-9 sm:pt-6 px-12 sm:px-8 sm:max-w-[374px] sm:mb-20"
    >
      <h3
        class="text-2xl md:text-xl sm:text-base font-bold text-promy-primary leading-9"
      >
        Mes révélations
      </h3>
      <div
        v-if="!isEmptyList"
        class="w-max mt-14 desktop:pr-4 sm:max-w-[310px]"
      >
        <pro-search
          :placeholder="'Recherche...'"
          iconName="search"
          classes="py-2 !pl-11"
          v-model="searchQuery"
          :is_loading="isLoading && searchQuery !== ''"
        />
      </div>
      <div class="my-5 sm:mt-8 space-y-4">
        <h4 class="font-semibold text-promy-primary" v-if="!showEmptyState">
          Les plus récentes :
        </h4>
        <list-item
          v-for="(revelation, index) in revelations"
          :key="index"
          :icon="'marker'"
          :title="revelation.adresse"
          :subtitle="revelation.departement"
          :status="revelation.status"
          @handleClick="
            $router.push({
              name: 'recap-revelations',
              params: { id: revelation.id },
            })
          "
        />
        <div class="m-auto w-fit" v-if="showEmptyState">
          <img
            src="/images/empty-state-revelation.svg"
            class="h-4/5 mt-7 bg-transparent m-auto"
            alt=""
          />
          <h3
            class="sm:text-sm text-xl sm:w-3/4 text-center font-bold mb-5 mt-12 text-promy-primary !w-full lg:!text-base md:!text-base"
          >
            {{ emptyRevelationsMessage }}
          </h3>
          <pro-button
            class="px-6 py-3 border-promy-green-350 rounded-lg mx-auto"
            btn="primary"
            @click="revelezPotentiel"
          >
            Révélez le potentiel
          </pro-button>
        </div>

        <div
          v-observe-visibility="{
            callback: (isVisible) =>
              isVisible &&
              this.last_page !== this.current_page &&
              handleScrolledToBottomOfRevelations(),
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      revelations: [],
      searchQuery: '',
      current_page: 1,
      last_page: 1,
      isLoading: false,
      emptyRevelationsMessage: '',
    }
  },
  created() {
    this.loadRevelations(this.current_page, this.searchQuery)
  },
  methods: {
    revelezPotentiel() {
      this.$store.dispatch('auth/me')
      this.$router.push({
        name: 'RevelationSteps',
        query: { id_address: this.$route.params.id_address },
      })
    },
    loadRevelations(current_page, searchQuery) {
      this.isLoading = true
      this.$http
        .get('/grand-publics/reveler-potentiels', {
          params: {
            page: current_page,
            search: _.isEmpty(searchQuery.trim()) ? '' : this.searchQuery,
          },
        })
        .then(({ data }) => {
          this.revelations =
            current_page === 1 ? data.data : [...this.revelations, ...data.data]
          this.last_page = data.meta.last_page
          this.isLoading = false
          this.emptyRevelationsMessage = this.isEmptyList
            ? 'Aucune révélation n’a encore été enregistrée'
            : 'Aucune donnée ne correspond à votre recherche'
        })
        .catch((error) => {
          this.toast('', "Une erreur s'est produite", 'danger')
          this.isLoading = false
        })
    },
    handleScrolledToBottomOfRevelations() {
      this.loadRevelations(++this.current_page, this.searchQuery)
    },
    searchAfterDebounce: _.debounce(
      function () {
        this.current_page = 1
        this.loadRevelations(this.current_page, this.searchQuery)
      },
      800, // 800 milliseconds
    ),
  },
  computed: {
    showEmptyState() {
      return this.revelations.length === 0 && !this.isLoading
    },
    isEmptyList() {
      return _.isEmpty(this.searchQuery) && _.isEmpty(this.revelations)
    },
  },
  watch: {
    searchQuery() {
      this.searchAfterDebounce()
    },
  },
}
</script>
